import WalletConnectionView from "./WalletConnectionView";
import StakingEnableView from "./StakingEnableView";
import { wait } from "@testing-library/dom";

export default function ConnectionSteps({
    setActive,
    stakeActive,
    enableStaking,
    waiting,
    interacting,
    initUpdateFired
}) {

    if (waiting || !initUpdateFired) {
        return (
            <div className="connection-step center-align">
                <h2 className="connection-step-headline">To continue, connect your wallet containing <br /><strong>Clear Water</strong> tokens</h2>
                <WalletConnectionView setActive={setActive} interacting={interacting} waiting={waiting} initUpdateFired={initUpdateFired} />
            </div>
        );
    } else if (!stakeActive) {
        return (
            <div className="connection-step center-align">
                <h2 className="connection-step-headline">Now that you're connected, you need<br />to enable staking with your wallet</h2>
                <StakingEnableView enableStaking={enableStaking} stakeActive={stakeActive} interacting={interacting} waiting={waiting} />
            </div>
        );
    } else {
        return (
            <div>Please wait while we load the staking experience</div>
        )
    }
}
