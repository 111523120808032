import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import truncateEthAddress from "./truncate";
import { FormatLocale } from "./helper";
export default function Owned({state, address, setActive}) {  
  return (
    <div>
      <div class="wallet-connect-status">
          <span className="wallet-address"><AccountBalanceWalletOutlinedIcon className="wallet-icon" fontSize="small" />{truncateEthAddress(address)}</span>
          <a class="connection-disconnect" href="javascript:void(0);" onClick={() => setActive(false)}>Disconnect</a>
      </div>
      <div className="wallet-stats">
          <div className="wallet-stat"><span className="seahorse-text">{FormatLocale(state.totalSupply)}</span><h3 class="headline-highlight">In your wallet</h3></div>
          <div className="wallet-stat"><span className="seahorse-text">{FormatLocale(state.totalStaked)}</span><h3 class="headline-highlight">Currently staked</h3></div>
          <div className="wallet-stat"><span className="seahorse-text">{FormatLocale(state.totalToken)}</span><h3 class="headline-highlight">Your total</h3></div>
      </div>
    </div>
  );
}
