import React, { useState } from "react";
import LockedStatus from "./LockedStatus";
import Collapse from "@mui/material/Collapse";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import { FormatLocale } from "./helper";
import { Block } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--cw-utility)",
  border: "none",
  outline: "none",
  borderRadius: "5px",
  boxShadow: 24,
  p: 2,
};

export default function Card({
  card_num,
  _unstake,
  _stake,
  Total_supply,
  Pool,
  reward,
  userInfo,
}) {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [stk, setstk] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  
  const time = Math.round(new Date().getTime() / 1000);

  const period = (sec) => {
    let time = new Date(sec * 1000).toLocaleString();
    let finaltime = time.split(",");
    return finaltime[0];
  };

  const daysUntilVested = (Pool, User) => {
    let today = new Date();
    let depositDay = new Date(User.depositTime * 1000);
    let poolLockPeriod = Pool.lockPeriodInDays;
    let delta = poolLockPeriod - (today.getTime() - depositDay.getTime()) / (1000 * 3600 * 24);

    return Math.ceil(((delta > 0 && delta < poolLockPeriod) ? delta : 0));
  }

  const apyNum = (data) => {
    if(data.lockPeriodInDays === "90" && data.apy === "400") {data.apy = "100"; console.log(`Adjusted ${data.lockPeriodInDays}`);}
    if(data.lockPeriodInDays === "180" && data.apy === "240") {data.apy = "120"; console.log(`Adjusted ${data.lockPeriodInDays}`);}
    if(data.lockPeriodInDays === "273" && data.apy === "200") {data.apy = "150"; console.log(`Adjusted ${data.lockPeriodInDays}`);}

    return data.apy ? parseFloat(data.apy / 10).toFixed(0) : 0;
  };

  window.userInfo = window.userInfo || {};
  window.userInfo[card_num] = userInfo;
  window.lockDateIsCorrected = true;

  window.Pool = window.Pool || {};
  window.Pool[card_num] = Pool;
  
  return (
    <div>
      <div className="table-grid info-block article">
          <div className="apy-column table-column sv-hide"><span>{apyNum(Pool)}<sup>%</sup></span><h3 className="headline-highlight">APY</h3></div>
          <div className="apy-column table-column lv-hide"><span className="gradient-text gold">{apyNum(Pool)}<sup>%</sup></span><h3 className="headline-highlight gradient-text gold">APY</h3></div>
          <div className="pool-end-column table-column"><span>{Pool.endDate > 0 ? period(Number(Pool.endDate)) : 0}</span><h3 className="headline-highlight">Pool End Date</h3></div>
          <div className="lock-up-column table-column">
            <span>{Pool.lockPeriodInDays} Days</span>
            <h3 className="headline-highlight">Lock-up Period</h3></div>
          <div className="staked-column table-column"><span className="seahorse-text">{FormatLocale(userInfo.total_invested / 1e18)}</span><h3 className="headline-highlight">Tokens Staked</h3></div>
          <div className="rewards-column table-column">
            <span className="gradient-text seahorse-text gold">{parseFloat(reward).toLocaleString()}</span> 
            <h3 className="headline-highlight">Rewards Earned</h3>
          </div>
          <div className="buttons-column table-column">
            {(time > Pool.startDate && time < Pool.endDate
                ?
                  <button className="button highlight" onClick={handleOpen}>Stake</button>
                :
                  <button className="button disabled" disabled="true">Stake</button>
            )}

            {(userInfo.total_invested > 0
              ? 
                <button className="button highlight" onClick={() => handleOpen1()}>Unstake</button>
              :
                <button className="button disabled" disabled="true">Unstake</button>
            )}

            {(userInfo.total_invested > 0
              ?
                <div style={{fontSize: "0.8rem", margin: "10px auto 0"}}>Your lock-up period ends in <strong>{daysUntilVested(Pool, userInfo)} days</strong></div>
              :
                ""
            )}
          </div>

        </div>


        <Modal
          open={open1}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal">
            <div className="modal_head">
              <h3 className="headline-highlight headline-modal">What amount would you like to unstake?</h3>
              <CancelIcon className="close-icon" onClick={() => handleClose1()} />
            </div>

            <p><strong>Available to unstake:</strong> <span className="seahorse-text smaller-horse">{parseFloat((userInfo.total_invested / 1e18).toFixed(2)).toLocaleString()}</span></p>
            {reward
              ? <p>Your rewards balance of <span className="seahorse-text smaller-horse">{parseFloat(reward).toLocaleString()}</span> will be sent to your wallet after unstaking</p>
              : ""
            }
            {daysUntilVested(Pool, userInfo) > 0
              ? <p className="tiny-text">Note: Because you are unstaking {daysUntilVested(Pool, userInfo)} days early, you will incur a <strong>20% tax on this transaction</strong></p>
              : <p><strong>You have completed your lock-up period, and may unstake your tokens tax-free!</strong></p>
            }
            <fieldset className="form-highlight">
              <input type="number" className="form-input" onChange={(event) => setstk(event.target.value)} required="true" placeholder={"Enter amount to unstake"} />
              <button className="button primary" onClick={() => { _unstake(card_num, stk, handleClose1); setstk(0); }}>Unstake</button>
            </fieldset>
          </Box>
        </Modal>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="modal">
            <div className="modal_head">
              <h3 className="headline-highlight headline-modal">What amount would you like to stake?</h3>
              <CancelIcon className="close-icon" onClick={() => handleClose()} />

              <p><strong>Available balance:</strong> <span className="seahorse-text smaller-horse">{parseFloat(Total_supply.toFixed(0)).toLocaleString()}</span></p>
              
              {userInfo.total_invested > 0
                ? <p><strong>Staked in this pool:</strong> <span className="seahorse-text smaller-horse">{FormatLocale(userInfo.total_invested / 1e18)}</span></p>
                : ""
              }
              {userInfo.total_invested > 0
                ? <p className="tiny-text">Note: Staking additional tokens will restart your lock-up period and rewards.  Your current rewards balance of <strong className="seahorse-text tiny-horse">{parseFloat(reward).toLocaleString()}</strong> will be sent to your wallet after staking.</p>
                : ""
              }

              <fieldset className="form-highlight">
                <input type="number" className="form-input" onChange={(event) => setstk(event.target.value)} required="true" placeholder={"Enter amount to stake"} />
                <button className="button primary" onClick={() => { _stake(card_num, stk, handleClose); setstk(0); }}>Stake</button>
              </fieldset>
            </div>
          </Box>
        </Modal>
    </div>
  );
}
