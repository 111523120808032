import Loading from "./Loading";

export default function WalletConnectionView({
    setActive,
    interacting,
    waiting,
    initUpdateFired
}) {

    let ret;

    if (waiting && interacting || !initUpdateFired && !waiting) {
        ret = <button className="button disabled super-large" disabled="true">Connecting <Loading /></button>;
    }
    else if (waiting) {
        ret = <button className="button primary super-large" onClick={() => setActive(() => true)}>Connect Wallet</button>
    }

    return (ret);

}
