import React, { useState, useEffect, useCallback } from "react";
import {
  chainID,
  chainName,
  Stakeabi,
  StakeAddress,
  Tokenabi,
  TokenAddress,
} from "./helper";
import "./App.css";
import Web3Modal from "web3modal";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Card from "./Card";
import Owned from "./Owned";
import ConnectionSteps from "./ConnectionSteps";
import Loading from "./Loading";
import Alert from '@mui/material/Alert';
import Collapse from "@mui/material/Collapse";
import { ThemeProvider, createTheme } from '@mui/material/styles';

const maxApproval = Web3.utils.toBN(
  "115792089237316195423570985008687907853269984665640564039457584007913129639935"

);

let web3Modal;
/**
 * @type {WalletConnectProvider}
 */
let provider;
/**
 * @type {Web3}
 */
let web3;
async function init() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        rpc: {
          56: "https://bsc-dataseed.binance.org/"
        },
        network: "binance",
      },
    },
  };

  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });
}

let _initUpdateFired = false;

const removeWalletConnectFromLocalStorage = () => {
  Object.keys(window.localStorage).forEach((key) => {
    if (key.toLowerCase().indexOf("walletconnect") !== -1) {
        window.localStorage.removeItem(key);
    }
  })
}

function App() {
  const [state, setState] = useState({
    reward1: 0,
    reward2: 0,
    reward3: 0,
    reward4: 0,
    totalSupply: 0,
    totalStaked: 0,
    totalToken: 0,
    contribution1: 0,
    contribution2: 0,
    contribution3: 0,
    contribution4: 0,
    pool1: {},
    pool2: {},
    pool3: {},
    pool4: {},
    userInfo1: {},
    userInfo2: {},
    userInfo3: {},
    userInfo4: {},
  });
  const [Stake, setStake] = useState({});
  const [Token, setToken] = useState({});
  const [address, setaddress] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [active, setactive] = useState(false);
  const [stakeActive, setstakeActive] = useState(false);
  const [interactingWithWallet, setInteractingWithWallet] = useState(false);
  const [waitingForConnect, setWaitingForConnect] = useState(true);
  const [statusMessage, _setStatusMessage] = useState({});

  const disconnect = async () => {
    setInteractingWithWallet(true);
    await web3Modal.clearCachedProvider();
    removeWalletConnectFromLocalStorage()
    setWaitingForConnect(true);
    setInteractingWithWallet(false);
  };

  const loadBlockdat = useCallback(async () => {
    let chain;
    await web3.eth.getChainId().then((values) => {
      chain = values;
    });
    if (chain === chainID) {
      const accounts = await new web3.eth.getAccounts();
      const stake = new web3.eth.Contract(Stakeabi, StakeAddress);
      const token = new web3.eth.Contract(Tokenabi, TokenAddress);
      const allow = await token.methods
        .allowance(accounts[0], StakeAddress)
        .call();


      setaddress(accounts[0]);
      setStake(() => stake);
      setToken(() => token);

      if (allow > 0) {
        setstakeActive(() => true);
      }
      
      const pool1 = await stake.methods.poolInfo(0).call();
      const pool2 = await stake.methods.poolInfo(1).call();
      const pool3 = await stake.methods.poolInfo(2).call();
      const pool4 = await stake.methods.poolInfo(3).call();

      const userinfo1 = await stake.methods.users(0, accounts[0]).call();
      const userinfo2 = await stake.methods.users(1, accounts[0]).call();
      const userinfo3 = await stake.methods.users(2, accounts[0]).call();
      const userinfo4 = await stake.methods.users(3, accounts[0]).call();

      const payout1 = await stake.methods._payout(0, accounts[0]).call();
      const reward1 = parseFloat(payout1 / 1e18).toFixed(2);

      const payout2 = await stake.methods._payout(1, accounts[0]).call();
      const reward2 = parseFloat(payout2 / 1e18).toFixed(2);

      const payout3 = await stake.methods._payout(2, accounts[0]).call();
      const reward3 = parseFloat(payout3 / 1e18).toFixed(2);
  
      const payout4 = await stake.methods._payout(3, accounts[0]).call();
      const reward4 = parseFloat(payout4 / 1e18).toFixed(2);

      const totalStaked =
        Math.floor(userinfo1.total_invested / 1e18) +
        Math.floor(userinfo2.total_invested / 1e18) +
        Math.floor(userinfo3.total_invested / 1e18) +
        Math.floor(userinfo4.total_invested / 1e18);

      const contribution1 = Math.floor(pool1.minContrib / 1e18);
      const contribution2 = Math.floor(pool2.minContrib / 1e18);
      const contribution3 = Math.floor(pool3.minContrib / 1e18);
      const contribution4 = Math.floor(pool4.minContrib / 1e18);

      const bal = await token.methods.balanceOf(accounts[0]).call();
      const totalToken = totalStaked + Math.floor(bal / 1e18);
      const totalSupply = parseFloat(bal / 1e18);

      setState((prev) => ({
        ...prev,
        pool1,
        pool2,
        pool3,
        pool4,
        userinfo1,
        userinfo2,
        userinfo3,
        userinfo4,
        reward1,
        reward2,
        reward3,
        reward4,
        totalStaked,
        contribution1,
        contribution2,
        contribution3,
        contribution4,
        totalToken,
        totalSupply,
      }));

    } else {
      setactive(false);
      setStatusMessage("error", `Clear Water staking is only supported on ${chainName} (chain ID ${chainID})`);
    }
    return;
  }, []);

  const loadBlockdata = useCallback(async () => {
    try {
      provider = await web3Modal.connect();
    } catch (e) {
      setStatusMessage("error", `Unable to connect to wallet.`)
      return;
    }

    web3 = new Web3(provider);

    loadBlockdat();
  }, [loadBlockdat]);

  const updateData = useCallback(async () => {
    if (
      active &&
      Stake.methods !== undefined &&
      Token.methods !== undefined &&
      address !== "0x0000000000000000000000000000000000000000"
    ) {
      const pool1Promise = Stake.methods.poolInfo(0).call();
      const pool2Promise = Stake.methods.poolInfo(1).call();
      const pool3Promise = Stake.methods.poolInfo(2).call();
      const pool4Promise = Stake.methods.poolInfo(3).call();
      const userInfo1Promise = Stake.methods.users(0, address).call();
      const userInfo2Promise = Stake.methods.users(1, address).call();
      const userInfo3Promise = Stake.methods.users(2, address).call();
      const userInfo4Promise = Stake.methods.users(3, address).call();
      const payout1Promise = Stake.methods._payout(0, address).call();
      const payout2Promise = Stake.methods._payout(1, address).call();
      const payout3Promise = Stake.methods._payout(2, address).call();
      const payout4Promise = Stake.methods._payout(3, address).call();

      const [
        pool1,
        pool2,
        pool3,
        pool4,
        userInfo1,
        userInfo2,
        userInfo3,
        userInfo4,
        payout1,
        payout2,
        payout3,
        payout4,
      ] = await Promise.all([
        pool1Promise,
        pool2Promise,
        pool3Promise,
        pool4Promise,
        userInfo1Promise,
        userInfo2Promise,
        userInfo3Promise,
        userInfo4Promise,
        payout1Promise,
        payout2Promise,
        payout3Promise,
        payout4Promise,
      ]);

      const reward1 = parseFloat(payout1 / 1e18).toFixed(2);
      const reward2 = parseFloat(payout2 / 1e18).toFixed(2);
      const reward3 = parseFloat(payout3 / 1e18).toFixed(2);
      const reward4 = parseFloat(payout4 / 1e18).toFixed(2);

      const totalStaked =
        Math.floor(userInfo1.total_invested / 1e18) +
        Math.floor(userInfo2.total_invested / 1e18) +
        Math.floor(userInfo3.total_invested / 1e18) +
        Math.floor(userInfo4.total_invested / 1e18);

      const contribution1 = Math.floor(pool1.minContrib / 1e18);
      const contribution2 = Math.floor(pool2.minContrib / 1e18);
      const contribution3 = Math.floor(pool3.minContrib / 1e18);
      const contribution4 = Math.floor(pool4.minContrib / 1e18);

      const bal = await Token.methods.balanceOf(address).call();
      const totalToken = totalStaked + Math.floor(bal / 1e18);
      const totalSupply = parseFloat(bal / 1e18);

      setState((prev) => ({
        ...prev,
        pool1,
        pool2,
        pool3,
        pool4,
        userInfo1,
        userInfo2,
        userInfo3,
        userInfo4,
        totalStaked,
        reward1,
        reward2,
        reward3,
        reward4,
        contribution1,
        contribution2,
        contribution3,
        contribution4,
        totalToken,
        totalSupply,
      }));

      _initUpdateFired = true;

    }
  }, [Stake.methods, Token.methods, active, address]);


  window._updateData = updateData;

  useEffect(() => {
    if (active) {
      async function fet() {
        setInteractingWithWallet(true);
        await init();
        await loadBlockdata();
        await updateData();
        setInteractingWithWallet(false);
        setWaitingForConnect(false);
        setStatusMessage("success", `Wallet successfully connected`);
      }
      fet();
    } else {
      setaddress("0x0000000000000000000000000000000000000000");
      if (provider) disconnect();
    }
  }, [active, loadBlockdata]);

  const _checkInit = () => {
    if (active && !_initUpdateFired) {
      setTimeout(async () => {
        await updateData();
        _checkInit();
      }, 2000);
    }
  }

  useEffect(() => {
    if (active && !_initUpdateFired) {
      _checkInit();
    }
  });

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      window.location.reload();
    };
    if (provider?.on) {
      provider.on("accountsChanged", handleAccountsChanged);
    }
    return () => {
      provider.off("accountsChanged", handleAccountsChanged);
    };
  }, []);

  const Approve = async () => {
    if (!active) return;
    try {
      setStatusMessage("info", "Awaiting approval from your wallet.")
      setInteractingWithWallet(true);
      await Token.methods
        .approve(StakeAddress, maxApproval)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          setstakeActive(true);
          setInteractingWithWallet(false);
          updateData();
          setStatusMessage("success", "Staking successfully enabled.")
        });
    } catch (err) {
      disconnect();
      setStatusMessage("error", "Staking has not been enabled, as the transaction was rejected.")
    }
  };

  const _stake = async (poolid, num, handleClose) => {
    if (!active) return;
    handleClose();
    num = num * 1000000000;
    num = num + "000000000";
    try {
      setStatusMessage("info", "Awaiting approval from your wallet.")
      await Stake.methods
        .stake(poolid, num)
        .send({ from: address })
        .on("transactionHash", async (hash) => {
          await updateData();
          setStatusMessage("info", `Transaction has been sent.  Data will be updated shortly after confirmation.`);
        })
        .on("receipt", async () => {
          await updateData();
          setStatusMessage("success", `Your transaction has completed successfully.  Congratulations on staking your Clear Water tokens!`);
        });
    } catch (err) {
      setStatusMessage("error", `Staking has not been completed, as the transaction was rejected.`);
    }
  };

  const _unstake = async (poolid, num, handleClose) => {
    if (!active) return;
    handleClose();
    num = num * 1000000000;
    num = num + "000000000";
    try {
      setStatusMessage("info", "Awaiting approval from your wallet.")
      await Stake.methods
        .unStake(poolid, num)
        .send({ from: address })
        .on("transactionHash", async (hash) => {
          await updateData();
          setStatusMessage("info", `Transaction has been sent.  Data will be updated shortly after confirmation.`);
        })
        .on("receipt", async () => {
          await updateData();
          setStatusMessage("success", `Your transaction has completed successfully, and your requested tokens have now been unstaked.`);
        });
    } catch (err) {
      setStatusMessage("error", `Unstaking has not been completed, as the transaction was rejected.  Attempting to increase approved allowance to correct.`);
      await Token.methods
        .approve(StakeAddress, maxApproval)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          setstakeActive(true);
          setInteractingWithWallet(false);
          updateData();
          setStatusMessage("success", "Staking successfully enabled.")
        });
    }
  };

  const [timer, setTimer] = useState(null);
  
  const setStatusMessage = (sev, msg) => {
    clearTimeout(timer);

    let state = {
      severity: sev,
      message: msg
    };

    _setStatusMessage({...state, active: true});
    if (sev !== "info") {
      setTimer(setTimeout(() => {
        _setStatusMessage(() => ({...state, active: false}));
      }, 6000))
    }
  }

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const getAlertClassName = () => {
    return `connection-status ${statusMessage.active ? "alert-open" : "alert-closed"}`;
  }
  
  return (
    <ThemeProvider theme={darkTheme}>
            
      <div className={getAlertClassName()}><Alert variant="filled" severity={statusMessage.severity}>{statusMessage.message} {statusMessage.severity === "info" && <Loading cls='light' />}</Alert></div>
      {statusMessage.active && statusMessage.severity === "info"
        ? <div className="connection-status-backdrop alert-open"></div>
        : ""
      }
      <div className="grid-container large-gap staking">
        <div>
          <h1 className="headline-secondary">Clear Water Staking</h1>
          <p>Put your investment to work and earn passive income by staking up to <strong>50,000,000 Clear Water tokens</strong> across four staking pools.</p>
          <p>To get started, simply connect your wallet and choose your preferred lock period.  There are no minimums required, and taxes will be waived<sup>*</sup> on staking transactions</p>
          <p className="small sv-hide">* Unstaking before the end of the selected lock-up period will result in a 20% tax being applied to your transaction</p>
        </div>
        <div>
          {(
            (waitingForConnect || !stakeActive || !_initUpdateFired)
              ? <div className="connection-waiting" style={{position: "relative", zIndex: 1}}>
                  <ConnectionSteps setActive={setactive} stakeActive={stakeActive} enableStaking={Approve} waiting={waitingForConnect} interacting={interactingWithWallet} initUpdateFired={_initUpdateFired} />
                </div>
              :
            <div style={{position: "relative", zIndex: 1}}>
              <Owned state={state} address={address} setActive={setactive} />
              <Card
                card_num={0}
                Approve={Approve}
                _unstake={_unstake}
                _stake={_stake}
                stakeActive={stakeActive}
                Total_supply={state.totalSupply}
                Pool={state.pool1}
                contribution={state.contribution1}
                reward={state.reward1}
                userInfo={state.userInfo1}
              />
              <Card
                card_num={1}
                Approve={Approve}
                _unstake={_unstake}
                _stake={_stake}
                stakeActive={stakeActive}
                Total_supply={state.totalSupply}
                Pool={state.pool2}
                contribution={state.contribution2}
                reward={state.reward2}
                userInfo={state.userInfo2}
              />
              <Card
                card_num={2}
                Approve={Approve}
                _unstake={_unstake}
                _stake={_stake}
                stakeActive={stakeActive}
                Total_supply={state.totalSupply}
                Pool={state.pool3}
                contribution={state.contribution3}
                reward={state.reward3}
                userInfo={state.userInfo3}
              />
              <Card
                card_num={3}
                Approve={Approve}
                _unstake={_unstake}
                _stake={_stake}
                stakeActive={stakeActive}
                Total_supply={state.totalSupply}
                Pool={state.pool4}
                contribution={state.contribution4}
                reward={state.reward4}
                userInfo={state.userInfo4}
              />
            </div>
          )}
        </div>
        <div className="lv-hide">
          <p className="small">* Unstaking before the end of the selected lock-up period will result in a 20% tax being applied to your transaction</p>
        </div>
      </div>
        
    </ThemeProvider>
  );
}

export default App;
