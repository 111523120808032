import Loading from "./Loading";

export default function StakingEnableView({
    enableStaking,
    stakeActive,
    interacting,
    waiting
}) {

    let ret;

    if (!waiting && interacting && !stakeActive) {
        ret = <button className="button disabled super-large" disabled="true">Enabling <Loading /></button>;
    }
    else if (!stakeActive) {
        ret = <button className="button primary super-large" onClick={() => enableStaking()}>Enable Staking</button>
    }

    return (ret);

}
